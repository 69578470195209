import React, { useEffect, useState } from 'react';
import FormStep from './FormStep';
import './Order.css';

export interface Order {
    userId: string,
    taskDescription: string,
    tags: string,
    preferredGender: string,
    serviceNeeded: string
}

const OrderPage: React.FC = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState<Order>({
        userId: '',
        taskDescription: '',
        tags: '',
        preferredGender: '',
        serviceNeeded: ''
    });
    const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);
    const [displayedText, setDisplayedText] = useState('');
    const [showReadyPrompt, setShowReadyPrompt] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [hideInitialText, setHideInitialText] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isWinner, setIsWinner] = useState(false);
    const [finalMessage, setFinalMessage] = useState('');

    const initialText = "Добрейший денечек/вечерочек! Только Машулька имеет доступ к этому сайту. Сейчас мы проверим ты ли это. Ты должна пройти через все вопросики. Это что то вроде логина :)";
    const finalTextPart1 = `
Кажется, что это действительно Машулька 😄. Спасибо тебе. Я не заслуживаю никакой пощады, потому что поступил ужасно в тот период времени. Мне очень стыдно за свои поступки. Я бы искренне, безумно хотел восстановить с тобой общение.

Я тут тебе плейлист музыкальный подготовил. Надеюсь он поднимет тебе настроение. А может нагонит какие-то приятные воспоминания. Признаюсь честно, наше общение в тот период я всегда вспоминаю с улыбкой, ну а позже вздыхаю немножко ахах, так как понимаю насколько сильно я облажался.

А вообще, если ты хотя бы читаешь эти строчки, то я уже очень искренне рад. Я честно, душу вложил в этот глупый сайт ахах. Сварганил я его быстренько относительно, но саму идейку продумывал долго ахах.

Я был полным идиотом и даже прощения просить смысла не вижу, так как абсолютно не заслуживаю даже разговора об этом.

Я не заслуживаю даже твоего ответа, и хоть минимальнейшего разговора. Не заслуживаю твоей улыбки и твоего внимания.

Мечтать не вредно, но я был бы просто безумно рад хотя бы просто с тобой поговорить. Спросить как дела в живую, по телефону. Рассказать тебе какую нибудь бредятину ахах, посмеяться над какой нибудь фигней :)

…ах да, плейлист.  
Вот ссылка на Spotify:
<a href="https://open.spotify.com/playlist/3mD33dZ1fjDu09ZV0iEOjz?si=fb20acc34b6d4c8c" target="_blank">Spotify Playlist</a>

Но если Spotify нету, я сделал на ютубчик:
<a href="https://www.youtube.com/watch?v=-rSrAj01KlQ&list=PLAawRdAqp7SiZIf58Egm8QlGdvTN9E3EA" target="_blank">YouTube Playlist</a>

Ты знаешь как я сильно музычку люблю. И этот плей-лист содержит не просто какие то классные песни, а именно те песни что все это время напоминали о тебе. И это нисколько не преувеличение.

Спасибо еще раз тебе если ты до сих пор читаешь это сообщение. Идейка мне показалась забавной 🙃.
<img src="/pic.png" alt="A meaningful description" />
    `;
    const typingSpeed = 40;

    useEffect(() => {
        let isTypingCancelled = false;

        if (displayedText === '' && !hideInitialText) {
            let accumulatedText = '';
            let index = 0;

            const displayText = () => {
                if (!isTypingCancelled && index < initialText.length) {
                    accumulatedText += initialText[index];
                    setDisplayedText(accumulatedText);
                    index++;
                    setTimeout(displayText, typingSpeed);
                } else if (!isTypingCancelled) {
                    setShowReadyPrompt(true);
                }
            };

            displayText();
        }

        return () => {
            isTypingCancelled = true;
        };
    }, []);

    useEffect(() => {
        if (isWinner) {
            let isTypingCancelled = false;
            let accumulatedText = '';
            let index = 0;

            const displayFinalMessagePart1 = () => {
                if (!isTypingCancelled && index < finalTextPart1.length) {
                    accumulatedText += finalTextPart1[index];
                    setFinalMessage(accumulatedText);
                    index++;
                    setTimeout(displayFinalMessagePart1, typingSpeed);
                }
            };

            displayFinalMessagePart1();

            return () => {
                isTypingCancelled = true;
            };
        }
    }, [isWinner]);

    const handleReadyResponse = (response: string) => {
        if (response === 'yes') {
            setIsReady(true);
            setHideInitialText(true);
        }
    };

    const nextStep = (e: React.FormEvent) => {
        e.preventDefault();
        if (step === 1) {
            if (formData.taskDescription.trim().toLowerCase() === 'new-york' || formData.taskDescription.trim().toLowerCase() === 'new york') {
                setErrorMessage(null);
                setStep(step + 1);
            } else {
                setErrorMessage("Неправильный ответ. Попробуй еще раз.");
            }
        } else if (step === 2) {
            if (formData.tags.trim().toLowerCase() === 'крестовский остров') {
                setErrorMessage(null);
                setStep(step + 1);
            } else {
                setErrorMessage("Неправильный ответ. Попробуй еще раз.");
            }
        } else if (step === 3) {
            if (formData.preferredGender.trim() === '2019') {
                setErrorMessage(null);
                setStep(step + 1);
            } else {
                setErrorMessage("Неправильный ответ. Попробуй еще раз.");
            }
        } else if (step === 4) {
            if (formData.serviceNeeded.trim().toLowerCase() === 'montreal') {
                setErrorMessage(null);
                setIsWinner(true);
            } else {
                setErrorMessage("Неправильный ответ. Попробуй еще раз.");
            }
        }
    };

    const handleChange = (input: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => ({ ...prevState, [input]: e.target.value }));
    };

    return (
        <>
            <div className="form-page">
                {!hideInitialText && (
                    <h1 className="form-header">{displayedText}</h1>
                )}
                {showReadyPrompt && !isReady && (
                    <div className="ready-prompt">
                        <h2>Готова?</h2>
                        <div className="navigation-buttons">
                            <button
                                type="button"
                                className="nav-button next-button"
                                onClick={() => handleReadyResponse('yes')}
                            >
                                Пошел в задницу, но давай. Любопытно же.
                            </button>
                            <button
                                type="button"
                                className="nav-button back-button"
                                onClick={() => window.location.href = 'https://www.youtube.com/watch?v=_7xMfIp-irg'}
                            >
                                Пошел в задницу.
                            </button>
                        </div>
                    </div>
                )}
                {isReady && !isOrderSubmitted && !isWinner && (
                    <form onSubmit={nextStep}>
                        {step === 1 && (
                            <div className="form-step">
                                <FormStep
                                    question="Столица Америки?(По версии Машки. подсказка: не Вашингтон. Пиши Английскими буквами)"
                                    inputName="taskDescription"
                                    value={formData.taskDescription}
                                    handleChange={handleChange('taskDescription')}
                                />
                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                            </div>
                        )}
                        {step === 2 && (
                            <div className="form-step">
                                <FormStep
                                    question="Станция метро где мы встретились в самый первый раз (пиши русскими буквами)?"
                                    inputName="tags"
                                    value={formData.tags}
                                    handleChange={handleChange('tags')}
                                />
                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                            </div>
                        )}
                        {step === 3 && (
                            <div className="form-step">
                                <FormStep
                                    question="Вкаком году мы встретились?"
                                    inputName="preferredGender"
                                    value={formData.preferredGender}
                                    handleChange={handleChange('preferredGender')}
                                />
                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                            </div>
                        )}
                        {step === 4 && (
                            <div className="form-step">
                                <FormStep
                                    question="В какой канадский город я свалил в Августе? (Пиши Английскими буквами)"
                                    inputName="serviceNeeded"
                                    value={formData.serviceNeeded}
                                    handleChange={handleChange('serviceNeeded')}
                                />
                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                            </div>
                        )}
                        <div className="navigation-buttons">
                            {step < 5 && (
                                <button type="submit" className="nav-button next-button">{step === 4 ? 'Finish' : 'Next'}</button>
                            )}
                        </div>
                    </form>
                )}
                {isWinner && (
                    <div
                        className="winner-message"
                        dangerouslySetInnerHTML={{ __html: finalMessage }}
                    />
                )}
            </div>
        </>
    );
};

export default OrderPage;
