import React from 'react';
import Order from "./Form/Order";
// import './App.css';

const App = () => {
  return (
    <div className="App">
      <Order/>
    </div>
  );
}

export default App;
