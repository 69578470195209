import React, { forwardRef } from 'react';

interface FormStepProps {
    question: string;
    inputName: string;
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormStep = forwardRef<HTMLInputElement, FormStepProps>(({ question, inputName, value, handleChange }, ref) => {
    return (
        <div className="form-step">
            <h2>{question}</h2>
            <input
                type="text"
                name={inputName}
                value={value}
                onChange={handleChange}
                placeholder="Type your answer here"
                className="form-input"
                ref={ref}
            />
        </div>
    );
});

export default FormStep;
